import { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";

const ZReports = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [zreports, setZReports] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/zreports?per=" + per;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let zreportsData = JSON.parse(JSON.stringify(response.data));
        let zreportsCount = zreportsData.pagination.count;
        setCount(zreportsCount);
        setZReports(zreportsData.zreports);

        let numPages = Math.ceil(zreportsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchZReports err = " + err.message));
  }, [apiBaseURL, navigate, per]);

  const loadZReports = () => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/zreports?per=" + per;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let zreportsData = JSON.parse(JSON.stringify(response.data));
        let zreportsCount = zreportsData.pagination.count;
        setCount(zreportsCount);
        setZReports(zreportsData.zreports);

        let numPages = Math.ceil(zreportsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchZReports err = " + err.message));
  };

  const searchZReports = (searchTermText) => {
    var accessToken = localStorage.getItem("esd_access_token");
    var url = apiBaseURL + "/zreports?search=" + searchTermText;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let zreportsData = JSON.parse(JSON.stringify(response.data));
        let zreportsCount = zreportsData.pagination.count;
        setCount(zreportsCount);
        setZReports(zreportsData.zreports);

        let numPages = Math.ceil(zreportsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchZReports err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterZReports(data);
  };

  const postZReport = () => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    var url = apiBaseURL + "/zreports";

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .post(url, {}, config)
      .then((response) => {
        let zreportData = JSON.parse(JSON.stringify(response.data));
        console.log("zreportsData: " + zreportData);

        setSuccessAlert("ZReport posted successfully.");
        setErrorAlert("");

        loadZReports();
      })
      .catch((err) => console.error("zreport post err = " + err.message));
  };

  const filterZReports = (data) => {
    var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("esd_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/zreports?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let zreportsData = JSON.parse(JSON.stringify(response.data));
        let zreportsCount = zreportsData.pagination.count;
        setCount(zreportsCount);
        setZReports(zreportsData.zreports);

        let numPages = Math.ceil(zreportsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter ZReports err = " + err.message));
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Z Reports</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "8px", marginTop: "-70px" }}
            >
              <div className="columns is-vcentered">
                <div className="column is-4">
                  <p className="bd-notification is-primary">
                    <b>Z Reports ({count})</b>
                    &nbsp;&nbsp;
                    <Link
                      to="/zreports-documentation"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Info
                    </Link>
                  </p>
                </div>
                <div className="column">
                  <p className="bd-notification is-primary is-pulled-right">
                    <div className="field">
                      <button className="button is-link" onClick={postZReport}>
                        Post Z-Report
                      </button>
                    </div>
                  </p>
                </div>
              </div>

              {alert}

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchZReports(e.target.value)}
                />
              </p>
              <br />
              <table className="table is-striped is-hoverable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>File Name</th>
                    <th>NET Amount</th>
                    <th>VAT Amount</th>
                    <th>Total Amount</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>#</th>
                    <th>File Name</th>
                    <th>NET Amount</th>
                    <th>VAT Amount</th>
                    <th>Total Amount</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </tfoot>
                <tbody>
                  {zreports.map((invoice) => (
                    <tr key={invoice.id}>
                      <th>{invoice.id}</th>
                      <th>{invoice.file_name}</th>
                      <td>{invoice.net_amount}</td>
                      <td>{invoice.vat_amount}</td>
                      <td>{invoice.total_amount}</td>
                      <td>{invoice.created_at.substring(0, 10)}</td>
                      <th>
                        <Link
                          to={{ pathname: `/zreports/${invoice.id}` }}
                          className="button is-primary"
                        >
                          View
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="container">
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <ReactPaginate
                    previousLabel={"Previous"}
                    previousClassName={"pagination-previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"pagination-ellipsis"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination-list"}
                    subContainerClassName={""}
                    activeClassName={"pagination-link is-current"}
                    pageClassName=""
                    pageLinkClassName="pagination-link"
                    disabledClassName="disabled"
                    nextClassName="pagination-next"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZReports;
