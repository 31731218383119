import { useEffect } from "react";
import WebNavbar from "../components/web_navbar";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          {/* <h1 className="title">Kodi</h1>
          <p className="subtitle">
            Electronic Signature Device signing, reporting and analytics tool.
          </p> */}

          <section className="section is-medium">
            <h1 className="title">Contact Us</h1>
            <h2 className="subtitle">
              You can reach us at info@strutstechnology.com or by phone +254 703
              397527.
            </h2>

            <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#00d1b2" }}
              >
                Struts Technology
              </a>
            </p>
          </section>
        </div>
      </section>
    </>
  );
};

export default Contact;
