import { Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import SignUp from "./pages/sign_up";

// Admin dashboard routes
import About from "./pages/about";
import Analytics from "./pages/admin/analytics";
import Configurations from "./pages/admin/configurations";
import Contact from "./pages/contact";
import Dashboard from "./pages/admin/dashboard";
import Documentation from "./pages/documentation/index";
import InvoiceDocumentation from "./pages/documentation/invoice_documentation";
import Invoices from "./pages/admin/invoices";
import InvoicesEmailFail from "./pages/admin/invoices/email-fail";
import Invoice from "./pages/admin/invoices/id";
import Logs from "./pages/admin/logs";
import Log from "./pages/admin/logs/id";
import Profile from "./pages/admin/profile";
import ProfileDocumentation from "./pages/documentation/profile_documentation";
import SigningAnalyticsDocumentation from "./pages/documentation/signing_analytics_documentation";
import StrutsAI from "./pages/struts_ai";
import SupportEngine from "./SupportEngine";
import SystemLogsDocumentation from "./pages/documentation/system_logs_documentation";
import VFDConfiguration from "./pages/admin/configurations/vfd_configuration";
import VFDConfigurationDocumentation from "./pages/documentation/vfd_configuration_documentation";
import XeroCallback from "./pages/xero-callback";
import ZReportsDocumentation from "./pages/documentation/zreports_documentation";
import ZReports from "./pages/admin/zreports";
import ZReport from "./pages/admin/zreports/id";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/configurations" element={<Configurations />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/documenation" element={<Documentation />} />
        <Route
          path="/invoice-documentation"
          element={<InvoiceDocumentation />}
        />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/invoices-email-fail" element={<InvoicesEmailFail />} />
        <Route path="/invoices/:id" element={<Invoice />} />
        <Route path="/logs" element={<Logs />} />
        <Route path="/logs/:id" element={<Log />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/profile-documentation"
          element={<ProfileDocumentation />}
        />
        <Route
          path="/signing-analytics-documentation"
          element={<SigningAnalyticsDocumentation />}
        />
        <Route path="/struts-ai" element={<StrutsAI />} />
        <Route
          path="/system-logs-documentation"
          element={<SystemLogsDocumentation />}
        />
        <Route path="/vfd-configuration" element={<VFDConfiguration />} />
        <Route
          path="/vfd-configuration-documentation"
          element={<VFDConfigurationDocumentation />}
        />
        <Route path="/xero-callback" element={<XeroCallback />} />
        <Route
          path="/zreports-documentation"
          element={<ZReportsDocumentation />}
        />
        <Route path="/zreports" element={<ZReports />} />
        <Route path="/zreports/:id" element={<ZReport />} />
      </Routes>

      <SupportEngine />
    </>
  );
};

export default App;
