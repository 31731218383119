import { Link } from "react-router-dom";

const CompanyView = (company) => {
  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <Link to="/zreports">
            <article className="tile is-child box">
              <p className="title has-text-info">{company.tin}</p>
              <p className="subtitle">Company TIN</p>
            </article>
          </Link>
        </div>

        <div className="tile is-parent">
          <Link to="/invoices">
            <article className="tile is-child box">
              <p className="title has-text-primary">{company.vrn}</p>
              <p className="subtitle">Company VRN</p>
            </article>
          </Link>
        </div>

        <div className="tile is-parent">
          <Link to="/invoices">
            <article className="tile is-child box">
              <p className="title has-text-warning">{company.gc}</p>
              <p className="subtitle">Current GC</p>
            </article>
          </Link>
        </div>

        <div className="tile is-parent">
          <Link to="/invoices">
            <article className="tile is-child box">
              <p className="title">{company.receipt_code}</p>
              <p className="subtitle">Receipt Codde</p>
            </article>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CompanyView;
