const TRAInvoiceTotalsView = (invoice) => {
  let qrCodeURL = `https://api.qrserver.com/v1/create-qr-code/?data=${invoice.verification_url}&amp;amp;size=100x100`;
  return (
    <>
      <div className="table">
        <table className="table">
          <tbody>
            <tr>
              <th style={{ width: "70%" }}>TOTAL EXCL OF TAX:</th>
              <td align="right">{invoice.gross_amount}</td>
            </tr>
            <tr>
              <th>
                <small>TAX A-18%</small>
              </th>
              <td align="right">
                <small>{invoice.vat}</small>
              </td>
            </tr>
            <tr>
              <th>TOTAL TAX:</th>
              <td align="right">{invoice.vat}</td>
            </tr>
            <tr>
              <th>TOTAL INCL OF TAX:</th>
              <td align="right">{invoice.grand_total}</td>
            </tr>
          </tbody>
        </table>
        <hr style={{ borderTop: "dotted 1px;" }} />
        <div className="row">
          <div className="col-xs-12 invoice-header">
            <center>
              <h4>
                <b>RECEIPT VERIFICATION CODE</b>
              </h4>
            </center>
            <center>
              <h4>{invoice.receipt_code}</h4>
            </center>
          </div>
        </div>
        <hr style={{ borderTop: "dotted 1px;" }} />
        <div className="col-xs-12 invoice-header">
          <center>
            <a href={invoice.verification_url} target="_blank" rel="noreferrer">
              <img
                id="barcode"
                src={qrCodeURL}
                alt=""
                title={invoice.receipt_code}
                width="100"
                height="100"
              />
            </a>
          </center>
          <hr style={{ borderTop: "dotted 1px;" }} />
        </div>
        <h4 style={{ textAlign: "center" }}>*** END OF LEGAL RECEIPT ***</h4>
      </div>
    </>
  );
};

export default TRAInvoiceTotalsView;
