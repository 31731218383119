import { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import "../../../App.css";

const Logs = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [logs, setLogs] = useState([]);
  const [per, setPer] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    const fetchLogs = () => {
      var url = apiBaseURL + "/logs?per=" + per;

      const config = {
        headers: {
          "x-esdws-token": accessToken,
          "x-esdws-application": "user",
        },
      };

      axios
        .get(url, config)
        .then((response) => {
          let logsData = JSON.parse(JSON.stringify(response.data));
          let logsCount = logsData.pagination.count;
          setCount(logsCount);
          setLogs(logsData.logs);

          let numPages = Math.ceil(logsCount / per);
          setPageCount(numPages);
        })
        .catch((err) => console.error("fetchLogs err = " + err.message));
    };

    window.scrollTo(0, 0);
    setPer(10);

    fetchLogs();

    const intervalId = setInterval(() => {
      fetchLogs(); // Fetch data every 10 seconds
    }, 10000);

    return () => clearInterval(intervalId);
  }, [apiBaseURL, per, navigate]);

  const searchLogs = (searchTermText) => {
    var accessToken = localStorage.getItem("esd_access_token");
    var url = apiBaseURL + "/logs?search=" + searchTermText;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let logsData = JSON.parse(JSON.stringify(response.data));
        let logsCount = logsData.pagination.count;
        setCount(logsCount);
        setLogs(logsData.logs);

        let numPages = Math.ceil(logsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchLogs err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterLogs(data);
  };

  const filterLogs = (data) => {
    var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("esd_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/logs?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let logsData = JSON.parse(JSON.stringify(response.data));
        let logsCount = logsData.pagination.count;
        setCount(logsCount);
        setLogs(logsData.logs);

        let numPages = Math.ceil(logsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Logs err = " + err.message));
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Logs</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <div style={{ marginBottom: "8px", marginTop: "-70px" }}>
                <b>Logs ({count})</b>
                &nbsp;&nbsp;
                <Link
                  to="/system-logs-documentation"
                  style={{
                    color: "#00d1b2",
                    fontWeight: "bold",
                    fontSize: "9pt",
                  }}
                >
                  Info
                </Link>
              </div>
              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchLogs(e.target.value)}
                />
              </p>
              <br />
              <table
                className="table is-striped is-hoverable"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Content</th>
                    <th>Type</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Content</th>
                    <th>Type</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </tfoot>
                <tbody>
                  {logs.map((log) => (
                    <tr
                      key={log.id}
                      style={{
                        backgroundColor: log.is_error ? "#f96854" : "#00ebc8",
                      }}
                    >
                      <th>{log.content}</th>
                      <td>{log.log_type}</td>
                      <td>{log.created_at}</td>
                      <th>
                        <Link
                          to={{ pathname: `/logs/${log.id}` }}
                          className="button is-primary"
                        >
                          View
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="container">
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <ReactPaginate
                    previousLabel={"Previous"}
                    previousClassName={"pagination-previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"pagination-ellipsis"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination-list"}
                    subContainerClassName={""}
                    activeClassName={"pagination-link is-current"}
                    pageClassName=""
                    pageLinkClassName="pagination-link"
                    disabledClassName="disabled"
                    nextClassName="pagination-next"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Logs;
