import TRACompanyView from "./tra_company_view";
import TRACustomerView from "./tra_customer_view";
import TRAInvoiceDetailsView from "./tra_invoice_details_view";
import TRAInvoiceItemsView from "./tra_invoice_items_view";
import TRAHeader from "./tra_header";
import TRAInvoiceFooter from "./tra_invoice_footer";

const TRAInvoicePreview = (company, invoice) => {
  let traCompanyView = TRACompanyView(company);
  let traCustomerView = TRACustomerView(invoice);
  let traInvoiceDetailsView = TRAInvoiceDetailsView(invoice);
  let traInvoiceItemsView = TRAInvoiceItemsView(invoice.items);
  let traInvoiceFooter = TRAInvoiceFooter(invoice);

  return (
    <>
      {/* Start of TRA Custom page */}
      {/* <!-- TRA Receipt page content --> */}
      <div className="ml-6" style={{ marginTop: "25px" }}>
        <div className="column is-three-fifths">
          <div
            className="ml-6"
            style={{
              backgroundImage:
                "url('../images/specimen.png');background-repeat:no-repeat;",
            }}
          >
            <TRAHeader />
            <div className="x_content">
              <section className="content invoice" id="inv">
                {traCompanyView}
                {traCustomerView}
                {traInvoiceDetailsView}
                {traInvoiceItemsView}
                {traInvoiceFooter}
              </section>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12"></div>
          </div>
        </div>
      </div>
      {/* End of TRA Custom page */}
    </>
  );
};

export default TRAInvoicePreview;
