import { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";

const InvoicesEmailFail = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/invoices?is_email_sent=false&per=" + per;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoicesData = JSON.parse(JSON.stringify(response.data));
        let invoicesCount = invoicesData.pagination.count;
        setCount(invoicesCount);
        setInvoices(invoicesData.invoices);

        let numPages = Math.ceil(invoicesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchInvoices err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const searchInvoices = (searchTermText) => {
    var accessToken = localStorage.getItem("esd_access_token");
    var url =
      apiBaseURL + "/invoices?is_email_sent=false&search=" + searchTermText;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoicesData = JSON.parse(JSON.stringify(response.data));
        let invoicesCount = invoicesData.pagination.count;
        setCount(invoicesCount);
        setInvoices(invoicesData.invoices);

        let numPages = Math.ceil(invoicesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchInvoices err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterInvoices(data);
  };

  const filterInvoices = (data) => {
    var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("esd_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url =
      apiBaseURL +
      "/invoices?is_email_sent=false&page=" +
      selectedPage +
      "&per=" +
      per;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoicesData = JSON.parse(JSON.stringify(response.data));
        let invoicesCount = invoicesData.pagination.count;
        setCount(invoicesCount);
        setInvoices(invoicesData.invoices);

        let numPages = Math.ceil(invoicesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Invoices err = " + err.message));
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Invoices</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <p>
                <b>Invoices Email Fail ({count})</b>
              </p>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchInvoices(e.target.value)}
                />
              </p>
              <br />
              <table className="table is-striped is-hoverable">
                <thead>
                  <tr>
                    <th>Invoice#</th>
                    <th>Gross Amt</th>
                    <th>VAT</th>
                    <th>Grand Total</th>
                    <th>Signature</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Invoice#</th>
                    <th>Gross Amt</th>
                    <th>VAT</th>
                    <th>Grand Total</th>
                    <th>Signature</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </tfoot>
                <tbody>
                  {invoices.map((invoice) => (
                    <tr key={invoice.id}>
                      <th>{invoice.invoice_number}</th>
                      <td>{invoice.gross_amount}</td>
                      <td>{invoice.vat}</td>
                      <td>{invoice.grand_total}</td>
                      <td>{invoice.signature.substring(0, 40)}</td>
                      <td>{invoice.created_at.substring(0, 10)}</td>
                      <th>
                        <Link
                          to={{
                            pathname: `/invoices/${invoice.id}`,
                          }}
                          className="button is-primary"
                        >
                          View
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="container">
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <ReactPaginate
                    previousLabel={"Previous"}
                    previousClassName={"pagination-previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"pagination-ellipsis"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination-list"}
                    subContainerClassName={""}
                    activeClassName={"pagination-link is-current"}
                    pageClassName=""
                    pageLinkClassName="pagination-link"
                    disabledClassName="disabled"
                    nextClassName="pagination-next"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvoicesEmailFail;
