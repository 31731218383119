import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import BarChart from "../../components/bar_chart";
import axios from "axios";
import ReactPaginate from "react-paginate";

const Dashboard = () => {
  const navigate = useNavigate();

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [companyTIN, setCompanyTIN] = useState("");
  const [currentDC, setCurrentDC] = useState(0);
  const [currentGC, setCurrentGC] = useState(0);
  const [totalInvoicesCount, setTotalInvoicesCount] = useState(0);
  const [signedInvoicesCount, setSignedInvoicesCount] = useState(0);
  const [failedInvoicesCount, setFailedInvoicesCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [processingInvoicesCount, setProcessingCount] = useState(0);
  const [logs, setLogs] = useState([]);
  const [per, setPer] = useState(8);
  const [zReportsCount, setZReportsCount] = useState(0);
  const [chartData, setChartData] = useState();

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    setPer(8);

    const getDashboardAnalytics = async () => {
      var accessToken = localStorage.getItem("esd_access_token");

      try {
        const res = await axios({
          url: apiBaseURL + "/analytics/dashboard",
          headers: {
            "x-esdws-token": accessToken,
            "x-esdws-application": "user",
          },
          method: "GET",
        });

        let dashboardAnalytics = JSON.parse(JSON.stringify(res.data));
        setCurrentDC(dashboardAnalytics.current_dc);
        setCurrentGC(dashboardAnalytics.current_gc);
        setTotalInvoicesCount(dashboardAnalytics.total_invoices_count);
        setFailedInvoicesCount(dashboardAnalytics.failed_invoices_count);
        setSignedInvoicesCount(dashboardAnalytics.signed_invoices_count);
        setProcessingCount(dashboardAnalytics.processing_invoices_count);
        setZReportsCount(dashboardAnalytics.z_reports_count);

        if (dashboardAnalytics.company && dashboardAnalytics.company.tin) {
          setCompanyTIN(dashboardAnalytics.company.tin);
        }

        let chartSummariesData = dashboardAnalytics.chart_summary;

        setChartData({
          labels: chartSummariesData.map((data) => data.month),
          datasets: [
            {
              label: "Invoices Signed",
              data: chartSummariesData.map((data) => data.total_invoices),
            },
          ],
        });
      } catch (err) {
        console.error("getDashboardAnalytics err = " + err);
      }
    };

    getDashboardAnalytics();

    const fetchLogs = () => {
      let accessToken = localStorage.getItem("esd_access_token");
      var url = apiBaseURL + "/logs?per=" + per;

      const config = {
        headers: {
          "x-esdws-token": accessToken,
          "x-esdws-application": "user",
        },
      };

      axios
        .get(url, config)
        .then((response) => {
          let logsData = JSON.parse(JSON.stringify(response.data));
          let logsCount = logsData.pagination.count;
          setCount(logsCount);
          setLogs(logsData.logs);

          let numPages = Math.ceil(logsCount / per);
          setPageCount(numPages);
        })
        .catch((err) => console.error("fetchLogs err = " + err.message));
    };

    fetchLogs();
  }, [apiBaseURL, navigate, per]);

  const searchLogs = (searchTermText) => {
    var accessToken = localStorage.getItem("esd_access_token");
    var url = apiBaseURL + "/logs?search=" + searchTermText;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let logsData = JSON.parse(JSON.stringify(response.data));
        let logsCount = logsData.pagination.count;
        setCount(logsCount);
        setLogs(logsData.logs);

        let numPages = Math.ceil(logsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchLogs err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterLogs(data);
  };

  const filterLogs = (data) => {
    var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("esd_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/logs?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let logsData = JSON.parse(JSON.stringify(response.data));
        let logsCount = logsData.pagination.count;
        setCount(logsCount);
        setLogs(logsData.logs);

        let numPages = Math.ceil(logsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Logs err = " + err.message));
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Dashboard</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <div style={{ marginBottom: "12px", marginTop: "-70px" }}>
                <b>Dashboard</b>
                &nbsp;&nbsp;
                <Link
                  to="/documenation"
                  style={{
                    color: "#00d1b2",
                    fontWeight: "bold",
                    fontSize: "9pt",
                  }}
                >
                  Info
                </Link>
              </div>
              <div className="tile is-ancestor">
                <div className="tile is-horizontal is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title">{totalInvoicesCount}</p>
                      <p className="subtitle">Total Processed</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-info">
                        {processingInvoicesCount}
                      </p>
                      <p className="subtitle">Currently Processing</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-primary">
                        {signedInvoicesCount}
                      </p>
                      <p className="subtitle">Signed Successfully</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-danger">
                        {failedInvoicesCount}
                      </p>
                      <p className="subtitle">Failed to Process</p>
                    </article>
                  </Link>
                </div>
              </div>
              <br />
              <div className="tile is-ancestor">
                <div className="tile is-parent">
                  <Link to="/zreports">
                    <article className="tile is-child box">
                      <p className="title has-text-info">{zReportsCount}</p>
                      <p className="subtitle">Z Reports Count</p>
                    </article>
                  </Link>
                </div>

                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-primary">{currentDC}</p>
                      <p className="subtitle">Current DC</p>
                    </article>
                  </Link>
                </div>

                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-warning">{currentGC}</p>
                      <p className="subtitle">Current GC</p>
                    </article>
                  </Link>
                </div>

                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title">{companyTIN}</p>
                      <p className="subtitle">Company TIN</p>
                    </article>
                  </Link>
                </div>
              </div>
              <br />
              <div className="tile is-ancestor">
                {/* <PieChart chartData={chartData} /> */}

                {/* <Line
                  data={barChartConfig.data}
                  options={barChartConfig.options}
                />
                <Pie
                  data={barChartConfig.data}
                  options={barChartConfig.options}
                /> */}

                {/* {chartRender} */}

                {/* <Bar data={chartData} /> */}

                {chartData && (
                  <BarChart
                    labels={chartData.labels}
                    datasets={chartData.datasets}
                  />
                )}
              </div>
              <br />
              <br />
              <div style={{ marginBottom: "15px" }}>
                <b>Logs ({count})</b>
                &nbsp;&nbsp;
                <Link
                  to="/system-logs-documentation"
                  style={{
                    color: "#00d1b2",
                    fontWeight: "bold",
                    fontSize: "9pt",
                  }}
                >
                  Info
                </Link>
              </div>
              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchLogs(e.target.value)}
                />
              </p>
              <br />
              <div>
                <table
                  className="table is-striped is-hoverable"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Content</th>
                      <th>Type</th>
                      <th>Created At</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>Content</th>
                      <th>Type</th>
                      <th>Created At</th>
                      <th>View</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {logs.map((log) => (
                      <tr
                        key={log.id}
                        style={{
                          backgroundColor: log.is_error ? "#f96854" : "#00ebc8",
                        }}
                      >
                        <th>{log.content}</th>
                        <td>{log.log_type}</td>
                        <td>{log.created_at}</td>
                        <th>
                          <Link
                            to={{ pathname: `/logs/${log.id}` }}
                            className="button is-primary"
                          >
                            View
                          </Link>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="container">
                  <nav
                    className="pagination is-centered"
                    role="navigation"
                    aria-label="pagination"
                  >
                    <ReactPaginate
                      previousLabel={"Previous"}
                      previousClassName={"pagination-previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"pagination-ellipsis"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination-list"}
                      subContainerClassName={""}
                      activeClassName={"pagination-link is-current"}
                      pageClassName=""
                      pageLinkClassName="pagination-link"
                      disabledClassName="disabled"
                      nextClassName="pagination-next"
                    />
                  </nav>
                </div>
              </div>
              <br />
              <br />
              {/* {{.bar}} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
