import { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const SystemLogsDocumentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">System Logs</h2>
              <p className="">
                System logs give you an insight of what is happening within the
                application, from application startup, invoice signing,
                Z-Reporting and any erros that might occur. When everything is
                going smoothly, the log entries will appear with the color{" "}
                <span style={{ color: "#00ebc8" }}>green</span> and when an
                issue occurs for one reason or the other, it will appear with
                the color <span style={{ color: "#f96854" }}>red</span>.
              </p>
              <br />
              <p>
                As invoices get processed, you will see{" "}
                <Link to="/logs" style={{ color: "#00d1b2" }}>
                  system logs
                </Link>{" "}
                similar to below;
              </p>
              <br />
              <br />
              <img src="/assets/logs-all-success.png" alt="System Logs" />
              <br />
              <br />
              Logs with some errors showing incase they occur.
              <br />
              <br />
              <br />
              <img src="/assets/logs.png" alt="System Logs" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SystemLogsDocumentation;
