import { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const SigningAnalyticsDocumentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Signing Analytics</h2>

              <p className="">
                Invoice processing analytics are available on the dashboard as
                well as the analytics page. They give you an insight of the
                signing process. The analytics and reports can be customized
                further to your preferences and liking. We could also send the
                analytics periodically to your designated email address(es) if
                you so wish.
              </p>

              <br />
              <p>
                As invoices get processed, you will see{" "}
                <Link to="/analytics" style={{ color: "#00d1b2" }}>
                  analytics
                </Link>{" "}
                similar to below;
              </p>

              <br />
              <br />
              <img src="/assets/analytics.png" alt="Analytics" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SigningAnalyticsDocumentation;
