import { Link } from "react-router-dom";

const AdminSideMenu = () => {
  return (
    <>
      <aside className="menu" style={{ padding: "10px" }}>
        <p className="menu-label">Dashboard</p>
        <ul className="menu-list">
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        </ul>
        <p className="menu-label">Invoices</p>
        <ul className="menu-list">
          <li>
            <Link to="/invoices">Invoices</Link>
          </li>
          {/* <li>
            <Link to="/invoices-email-fail">Email Fail</Link>
          </li> */}
          <li>
            <Link to="/zreports">Z Reports</Link>
          </li>
        </ul>
        <p className="menu-label">Analytics</p>
        <ul className="menu-list">
          <li>
            <Link to="/analytics">Signing Analytics</Link>
          </li>
          <li>
            <Link to="/logs">System Logs</Link>
          </li>
        </ul>
        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          {/* <li>
            <Link to="/configurations">Configurations</Link>
          </li> */}
          <li>
            <Link to="/vfd-configuration">VFD Configuration</Link>
          </li>
          <li>
            <Link to="/profile">Profile</Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default AdminSideMenu;
