import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TRACompanyView from "./tra_company_view";
import TRACustomerView from "./tra_customer_view";
import TRAInvoiceDetailsView from "./tra_invoice_details_view";
import TRAInvoiceItemsView from "./tra_invoice_items_view";
import TRAInvoiceTotalsView from "./tra_invoice_totals_view";
import TRAHeader from "./tra_header";
import TRAInvoiceFooter from "./tra_invoice_footer";

const TRAInvoicePreview = (id) => {
  const navigate = useNavigate();

  const [invoice, setInvoice] = useState({});

  let traCompanyView = useRef();
  let traCustomerView = useRef();
  let traInvoiceDetailsView = useRef();
  let traInvoiceItemsView = useRef();
  let traInvoiceTotals = useRef();
  let traInvoiceFooter = useRef();

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/invoices/" + id;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoiceData = JSON.parse(JSON.stringify(response.data));
        setInvoice(invoiceData);

        traCompanyView.current = TRACompanyView(invoiceData.company);
        traCustomerView.current = TRACustomerView(invoiceData);
        traInvoiceDetailsView.current = TRAInvoiceDetailsView(invoiceData);
        traInvoiceItemsView.current = TRAInvoiceItemsView(invoiceData.items);
        traInvoiceTotals.current = TRAInvoiceTotalsView(invoiceData);
        traInvoiceFooter.current = TRAInvoiceFooter(invoiceData);
      })
      .catch((err) => console.error("fetchInvoices err = " + err.message));
  }, [apiBaseURL, id, navigate, invoice.verification_url]);

  return (
    <>
      {/* Start of TRA Custom page */}
      {/* <!-- TRA Receipt page content --> */}
      <div className="ml-6" style={{ marginBottom: "8px", marginTop: "-80px" }}>
        <div className="column is-three-fifths">
          <div
            className="ml-6"
            style={{
              backgroundImage:
                "url('../images/specimen.png');background-repeat:no-repeat;",
            }}
          >
            <TRAHeader />
            <div className="x_content">
              <section className="content invoice" id="inv">
                {traCompanyView.current}
                {traCustomerView.current}
                {traInvoiceDetailsView.current}
                {traInvoiceItemsView.current}
                {traInvoiceFooter.current}
              </section>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12"></div>
          </div>
        </div>
      </div>
      {/* End of TRA Custom page */}
    </>
  );
};

export default TRAInvoicePreview;
