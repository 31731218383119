const TRACustomerView = (invoice) => {
  let customerPhone = invoice.customer_phone;

  return (
    <>
      <div className="row">
        <div className="col-xs-12 invoice-header">
          <hr style={{ borderTop: "dotted 1px;" }} />
          <b>CUSTOMER NAME: </b> {invoice.customer_name}
          <br />
          <b>CUSTOMER ID TYPE: </b> TAXPAYER INDETIFICATION NUMBER
          <br />
          <b>CUSTOMER ID: </b> ASDFGA-35T34-SASF
          <br />
          {customerPhone && (
            <div>
              <b>CUSTOMER MOBILE: </b> {customerPhone}
              <br />
            </div>
          )}
          <b>CUSTOMER TIN: </b> {invoice.customer_tin}
          <br />
        </div>
      </div>
    </>
  );
};

export default TRACustomerView;
