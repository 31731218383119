const TRAHeader = () => {
  return (
    <>
      <div className="x_title">
        <h4 style={{ textAlign: "center" }}>*** START OF LEGAL RECEIPT ***</h4>
        <center>
          <img
            alt="TRA Logo"
            src="/assets/tra_logo.png"
            style={{ maxHeight: "6.50rem" }}
          />
        </center>
        <div className="clearfix"></div>
      </div>
    </>
  );
};

export default TRAHeader;
