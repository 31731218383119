import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import TRAInvoicePreview from "../shared/tra_invoice_view";

const Invoice = () => {
  let { id } = useParams();

  let traInvoiceView = TRAInvoicePreview(id);

  const navigate = useNavigate();

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Invoice View</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column">
              {/* Start of TRA Custom page */}
              {traInvoiceView}
              {/* End of TRA Custom page */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Invoice;
