import { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const ZReportsDocumentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">ZReports</h2>
              <p className="">
                Z-Reports are sent out automatically at the end of the day, and
                after all invoices have been processed successfully through TRA.
              </p>
              <br />
              <p className="">
                If you wish to post a Z-Report as you close the day, you can do
                so by clicking on the <b>Post Z-Report</b> button. You should
                get a success response after that. Note that a Z-Report will be
                sent only after all documents processed have been posted
                successfully to TRA.
              </p>
              <br />
              <p>
                As Z-Reports get processed, you will see a{" "}
                <Link to="/zreports" style={{ color: "#00d1b2" }}>
                  report
                </Link>{" "}
                similar to below;
              </p>
              <br />
              <br />
              <img src="/assets/zreports.png" alt="Z Reports" />
              <br />
              <br />
              <br />
              The following is a sample single Z-Report view;
              <br />
              <br />
              <br />
              <img src="/assets/single-zreport-view.png" alt="Z Reports" />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZReportsDocumentation;
