import { useEffect, useState } from "react";
import WebNavbar from "../components/web_navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    console.log(accessToken);
    if (accessToken) {
      navigate("/dashboard");
    }

    window.scrollTo(0, 0);
    if (
      localStorage.getItem("welcome_message") &&
      localStorage.getItem("welcome_message").length > 0
    ) {
      setSuccessAlert(localStorage.getItem("welcome_message"));
      localStorage.setItem("welcome_message", "");
    }
  }, [navigate]);

  const loginUser = (event) => {
    // Prevent page reload
    event.preventDefault();
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    setIsLoading(true);
    axios({
      url: `${apiBaseURL}/sessions`,
      method: "POST",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-esdws-application": "user",
        "x-client-identifier": "web",
      },
      data: {
        email: username,
        password: password,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.user.id) {
          localStorage.setItem("esd_user_id", response.data.user.id);
          localStorage.setItem(
            "esd_access_token",
            response.headers["x-esdws-token"]
          );
          localStorage.setItem("esd_user", response.data.user);

          var welcomeMessage = "Login successful. Redirecting to dashboard...";
          setSuccessAlert(welcomeMessage);
          setErrorAlert("");
          // redirect to dashboard after a successful login
          navigate("/dashboard");
        } else {
          setErrorAlert("Login failed.");
          setSuccessAlert("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorAlert("Login failed.");
        setSuccessAlert("");
      });
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-one-third">
            <form onSubmit={loginUser}>
              <div
                className="container"
                style={{
                  borderRadius: "3px;padding: 15px",
                }}
              >
                <div className="field">
                  <label className="label">Username</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Enter Username"
                      name="username"
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Password</label>
                  <div className="control">
                    <input
                      className="input"
                      type="password"
                      placeholder="Enter Password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          loginUser();
                        }
                      }}
                      required
                    />
                  </div>
                </div>

                {!isLoading && (
                  <div className="field">
                    <button className="button is-link" onClick={loginUser}>
                      Login
                    </button>
                  </div>
                )}
              </div>
            </form>

            <br />
            {alert}
          </div>
          <div className="column"></div>
        </div>
      </section>
    </>
  );
};

export default Login;
