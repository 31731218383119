import { useEffect, useState } from "react";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import LineChart from "../../components/line_chart";
import PieChart from "../../components/pie_chart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const [companyTIN, setCompanyTIN] = useState("");
  const [currentDC, setCurrentDC] = useState(0);
  const [currentGC, setCurrentGC] = useState(0);
  const [totalInvoicesCount, setTotalInvoicesCount] = useState(0);
  const [signedInvoicesCount, setSignedInvoicesCount] = useState(0);
  const [failedInvoicesCount, setFailedInvoicesCount] = useState(0);
  const [processingInvoicesCount, setProcessingCount] = useState(0);
  const [zReportsCount, setZReportsCount] = useState(0);
  const [chartData, setChartData] = useState();

  const getDashboardAnalytics = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("esd_access_token");

    try {
      const res = await axios({
        url: apiBaseURL + "/analytics/dashboard",
        headers: {
          "x-esdws-token": accessToken,
          "x-esdws-application": "user",
        },
        method: "GET",
      });

      let dashboardAnalytics = JSON.parse(JSON.stringify(res.data));
      setCurrentDC(dashboardAnalytics.current_dc);
      setCurrentGC(dashboardAnalytics.current_gc);
      setTotalInvoicesCount(dashboardAnalytics.total_invoices_count);
      setFailedInvoicesCount(dashboardAnalytics.failed_invoices_count);
      setSignedInvoicesCount(dashboardAnalytics.signed_invoices_count);
      setProcessingCount(dashboardAnalytics.processing_invoices_count);
      setZReportsCount(dashboardAnalytics.z_reports_count);

      if (dashboardAnalytics.company && dashboardAnalytics.company.tin) {
        setCompanyTIN(dashboardAnalytics.company.tin);
      }

      let chartSummariesData = dashboardAnalytics.chart_summary;

      setChartData({
        labels: chartSummariesData.map((data) => data.month),
        datasets: [
          {
            label: "Invoices Signed",
            data: chartSummariesData.map((data) => data.total_invoices),
          },
        ],
      });
    } catch (err) {
      console.error("getDashboardAnalytics err = " + err.message);
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    getDashboardAnalytics();
  }, [navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Analytics</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-70px" }}
            >
              <b>Analytics</b>
              &nbsp;&nbsp;
              <Link
                to="/signing-analytics-documentation"
                style={{
                  color: "#00d1b2",
                  fontWeight: "bold",
                  fontSize: "9pt",
                }}
              >
                Info
              </Link>
              <br />
              <div className="tile is-ancestor">
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title">{totalInvoicesCount}</p>
                      <p className="subtitle">Total Invoices</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-info">
                        {processingInvoicesCount}
                      </p>
                      <p className="subtitle">Processing</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-primary">
                        {signedInvoicesCount}
                      </p>
                      <p className="subtitle">Signed Successfully</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-danger">
                        {failedInvoicesCount}
                      </p>
                      <p className="subtitle">Failed to Process</p>
                    </article>
                  </Link>
                </div>
              </div>
              <br />
              <div className="tile is-ancestor">
                <div className="tile is-parent">
                  <Link to="/zreports">
                    <article className="tile is-child box">
                      <p className="title has-text-info">{zReportsCount}</p>
                      <p className="subtitle">Z Reports Count</p>
                    </article>
                  </Link>
                </div>

                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-primary">{currentDC}</p>
                      <p className="subtitle">Current DC</p>
                    </article>
                  </Link>
                </div>

                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-warning">{currentGC}</p>
                      <p className="subtitle">Current GC</p>
                    </article>
                  </Link>
                </div>

                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title">{companyTIN}</p>
                      <p className="subtitle">Company TIN</p>
                    </article>
                  </Link>
                </div>
              </div>
              <br />
              {chartData && (
                <LineChart
                  labels={chartData.labels}
                  datasets={chartData.datasets}
                />
              )}
              <br />
              <br />
              {chartData && (
                <PieChart
                  labels={chartData.labels}
                  datasets={chartData.datasets}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
