import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import ZReportView from "../shared/zreport_view";
import axios from "axios";

const ZReport = () => {
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  let { id } = useParams();

  const navigate = useNavigate();

  const [zreport, setZReport] = useState({});

  var zReportView = useRef();

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/zreports/" + id;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let zreportData = JSON.parse(JSON.stringify(response.data));
        setZReport(zreportData);
        zReportView.current = ZReportView(zreportData);
      })
      .catch((err) => console.error("fetchZReports err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">ZReport #{zreport.id}</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>

            <div
              className="column is-three-quarters"
              style={{ marginBottom: "8px", marginTop: "-70px" }}
            >
              {zreport && zReportView.current}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZReport;
